<template>
  <div>
    <el-select
      v-model="selectedAddress"
      style="width: calc(100% - 20px)"
      ref="field"
      filterable
      clearable
      remote
      value-key="key"
      :loading="loading"
      :remote-method="search"
      :placeholder="placeholder"
      @clear="clear"
    >
      <i slot="prefix" class="el-icon-search" style="margin-left: 5px; font-weight: bold"></i>
      <el-option
        v-for="item in options"
        :key="item.key"
        :label="item.name"
        :value="item">
      </el-option>
    </el-select>
    <i
      :class="{'el-icon-arrow-down': !showAddressObjects, 'el-icon-arrow-up': showAddressObjects}"
      style="margin-left: 5px; font-weight: bold; cursor:pointer;"
      @click="showAddressObjects = !showAddressObjects"></i>
    <address-objects
      :is-active.sync="showAddressObjects"
      :append-to-body="true"
      :dropdown-width="dropdownWidth"
      :level="level"
      :required-level="requiredLevel"
      :selected-address="selectedAddress"
      @set-address="loadAddress"
      style="margin-top: 5px"
    ></address-objects>
  </div>
</template>

<script>
import AddressObjects from '@/core/infrastructure/components/AddressField/AddressObjects'
export default {
  name: 'address_field',
  components: { AddressObjects },
  props: {
    level: {
      type: String,
      default: 'street'
    },
    placeholder: {
      type: String
    },
    searchLimit: {
      type: Number,
      default: 50
    },
    value: {
      type: Object
    },
    requiredLevel: {
      type: String
    }
  },
  data () {
    return {
      selectedAddress: this.value || {},
      options: [],
      showAddressObjects: false,
      dropdownWidth: 100,
      loading: false
    }
  },
  mounted () {
    this.dropdownWidth = this.$el.offsetWidth > 500 ? 500 : this.$el.offsetWidth
  },
  computed: {
    resultedValue () {
      if (Object.keys(this.selectedAddress || {}).length === 0) {
        return null
      }
      let result = {}

      result.id = this.selectedAddress.address_id || null
      result.regioncode = this.selectedAddress.regioncode || null
      result.house_houseid = this.selectedAddress.house_houseid || null
      result.addrobj_aoid = this.selectedAddress.addrobj_aoid || null
      result.address_level_id = this.selectedAddress.address_level_id || null
      result.house = this.selectedAddress.house || null

      return result
    }
  },
  methods: {
    clear () {
      this.selectedAddress = null
    },
    async loadAddress (address) {
      if (address.type && Object.keys(address.data).length > 0) {
        let url = null
        if (address.type === 'house') {
          if (address.data.house_houseid) {
            url = `${this.$config.api}/fiasaddressservice/addresses/houses?house_houseid=${address.data.house_houseid}`
          } else {
            url = `${this.$config.api}/fiasaddressservice/addresses/houses?addrobj_aoid=${address.data.previous}&house=${address.data.house}`
          }
        } else {
          url = `${this.$config.api}/fiasaddressservice/addresses/address_objects/${address.data.id}`
        }
        if (!url) {
          return false
        }
        let data = await this.$http.get(url)
        if (!data.data) {
          console.warn('address not found')
          return false
        }
        let option = Object.assign(data.data, {
          key: `${data.data.addrobj_aoid}_${data.data.house_houseid}_${data.data.address_level_id}`
        })
        if (!this.options.find(item => item.key === option.key)) {
          this.options.push(option)
        }
        this.selectedAddress = option
      }
    },
    async search (query) {
      if (!query) {
        this.options = []
        return false
      }
      this.loading = true
      let data = await this.$http.get(`${this.$config.api}/fiasaddressservice/addresses/address_objects/${this.level}/${query}?limit=${this.searchLimit}`)
      this.options = data.data.map((item) => {
        return Object.assign(item, {
          key: `${item.addrobj_aoid}_${item.house_houseid}_${item.address_level_id}`
        })
      })
      this.loading = false
    }
  },
  watch: {
    value: {
      handler (value) {
        this.options = [value]
        this.selectedAddress = value
      },
      deep: true
    },
    selectedAddress: {
      handler () {
        this.$emit('input', this.resultedValue)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
</style>
